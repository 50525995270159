import React, { useEffect, useRef, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReCAPTCHA from "react-google-recaptcha"
import { useForm } from "react-hook-form"
import Button from "./UI/Button"
import Label from "./UI/Label"
import Text from "./UI/Text"
import { location, origin, path, useInterval } from "../utils"
import Popup from "./UI/Popup"
import Title from "./UI/Title"
import { useBeforeUnload } from "react-use"

function ProForm({ locale, inline }) {
  const data = useStaticQuery(graphql`
    {
      variable_fr: strapiVariable(locale: { eq: "fr" }) {
        phone
      }
      variable_en: strapiVariable(locale: { eq: "en" }) {
        phone
      }
    }
  `)

  const { variable_fr, variable_en } = data
  const variable = locale === "fr" ? variable_fr : variable_en
  const [submited, setSubmited] = useState(false)
  const captchaRef = useRef()
  const { getValues, setValue, register, trigger: validate, errors } = useForm({
    mode: "onChange",
  })

  useEffect(() => {
    register({ name: "g-recaptcha-response" }, { required: true })
  }, [])

  useInterval(() => {
    if (captchaRef.current && getValues().email.length > 5) {
      setValue("g-recaptcha-response", captchaRef.current.getValue())
      validate()
    }
  }, 500)

  const send = () => {
    validate()
    if (Object.keys(errors).length > 0) return
    const formData = new FormData()
    const urlParams = new URLSearchParams(window.location.search)
    formData.append(
      "captcha_settings",
      JSON.stringify({
        keyname: "Wizito2",
        fallback: "true",
        orgId: "00D58000000Oc8R",
        ts: JSON.stringify(new Date().getTime()),
      })
    )
    formData.append("oid", "00D58000000Oc8R")
    formData.append(
      "lead_source",
      urlParams.get("gclid") || (origin() || "").match(/ads/)
        ? "Adwords"
        : "Site internet"
    )
    formData.append("00N5800000DJ7o3", "professionnel")
    formData.append("00NAM000000Pd5r", path())
    // old annonce field
    formData.append("00N5800000DgL9B", origin() || "")
    if (urlParams.get("gclid"))
      formData.append("00N5800000DCFMB", urlParams.get("gclid"))
    if (urlParams.get("sales")) {
      // const sales = { fabien: '0051i000001KSBzAAO', clotilde: '00558000002QMUIAA4', carlos: '0051i000002NVBcAAO', vanessa: '005AM000000HrvrYAC', guillaume: '0051i000000Yly2AAC', leo: '0051i000002NT82AAG', lorenzo: '00558000001LH4rAAG' }
      formData.append("00NAM000000Q4mU", urlParams.get("sales"))
    }
    formData.append("00N1i000002YmWF", locale === "fr" ? "FR" : "UK")
    Object.entries(getValues()).forEach(([k, v]) => formData.append(k, v))
    if (window.gtag) {
      window.gtag("event", "contact")
      window.gtag("event", "conversion", {
        send_to: "AW-951050257/CsGfCK_htPUCEJHAv8UD",
      })
    }
    fetch(
      `https://webto.salesforce.com/servlet/servlet.WebToLead?encoding=UTF-8&${new URLSearchParams(
        formData
      ).toString()}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        mode: "no-cors",
      }
    )
    setSubmited(true)
  }

  return (
    <div className={inline ? "max-w-xl mx-auto my-5" : "-mx-4 lg:mx-0"}>
      {!submited ? (
        <>
          {!inline && (
            <>
              <a className="-mt-4" href={`tel:${variable.phone}`}>
                {variable.phone}
              </a>
              <div className="hidden lg:block my-4 text-gray-600 text-lg leading-tight">
                {locale === "fr"
                  ? "Vous souhaitez louer une borne photo, avoir des informations, obtenir un devis ou prendre un café ? Alors ce formulaire est fait pour vous !"
                  : "Do you want to rent a photo booth, get information, a quote or share a coffee? Then this form is for you!"}
              </div>
            </>
          )}
          <div className="flex justify-start items-center flex-wrap -mx-2 lg:-mx-5">
            <Label
              locale={locale}
              title={locale === "fr" ? "Prénom" : "First name"}
              className="w-1/2+sm lg:w-1/2+m"
              error={errors.first_name}
            >
              <input
                className="form-input w-full text-base"
                name="first_name"
                ref={register({ required: true, minLength: 3 })}
              />
            </Label>
            <Label
              locale={locale}
              title={locale === "fr" ? "Nom" : "Last name"}
              className="w-1/2+sm lg:w-1/2+m"
              error={errors.last_name}
            >
              <input
                className="form-input w-full text-base"
                name="last_name"
                ref={register({ required: true, minLength: 3 })}
              />
            </Label>
          </div>
          <div className="flex justify-start items-center flex-wrap -mx-2 lg:-mx-5">
            <Label
              locale={locale}
              title="Email"
              className="w-1/2+sm lg:w-1/2+m"
              error={errors.email}
            >
              <input
                className="form-input w-full text-base"
                name="email"
                ref={register({
                  required: true,
                  pattern: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                })}
              />
            </Label>
            <Label
              locale={locale}
              title={locale === "fr" ? "Téléphone" : "Phone"}
              className="w-1/2+sm lg:w-1/2+m"
              error={errors.phone}
            >
              <input
                className="form-input w-full text-base"
                name="phone"
                ref={register({
                  required: true,
                  pattern2: /^(\+\d{1})?\d{10}$/,
                })}
                onChange={e => {
                  e.target.value = e.target.value.replace(/\s/, "")
                  if (e.target.value.match(/\+33/)) {
                    e.target.value = e.target.value.replace("+33", "0")
                    validate()
                  }
                }}
              />
            </Label>
          </div>
          {locale === "fr" && (
            <div className="flex justify-start items-center flex-wrap -mx-2 lg:-mx-5">
              <Label
                locale={locale}
                title={locale === "fr" ? "Code postal" : "Postcode"}
                className="w-1/2+sm lg:w-1/2+m"
                error={errors["00N1i000000c1sT"]}
              >
                <input
                  className="form-input w-full text-base"
                  name="00N1i000000c1sT"
                  ref={register({ required: true, minLength: 3 })}
                />
              </Label>
            </div>
          )}
          <div className="flex justify-start items-center flex-wrap -mx-2 lg:-mx-5">
            <Label
              locale={locale}
              title={
                locale === "fr"
                  ? "Parlez-nous de votre projet"
                  : "Talk about your project"
              }
              className="w-full"
              error={errors.description}
            >
              <textarea
                className="form-input w-full h-24 text-base"
                name="description"
                ref={register({ required: true, minLength: 20 })}
              />
            </Label>
          </div>
          <div className="flex justify-center transform scale-90">
            <ReCAPTCHA
              ref={captchaRef}
              sitekey="6LcNt_QZAAAAAEXTaiuAtQ_k6Z_7gNftbqZ3gpDj"
            />
          </div>
          <Button
            className="mx-auto mt-2"
            onClick={send}
            disabled={Object.keys(errors).length > 0}
          >
            {locale === "fr" ? "Envoyer" : "Send"}
          </Button>
        </>
      ) : (
        <>
          <img
            className="rounded shadow w-full"
            src="https://media0.giphy.com/media/g9582DNuQppxC/giphy.gif"
          />
          <div className="text-center font-bold mt-4">
            {locale === "fr" ? (
              <>
                Votre demande a bien été envoyée !<br />
                On revient vers vous très vite.
              </>
            ) : (
              <>
                Your request has been sent!
                <br />
                We will get back to you very quickly.
              </>
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default function Form({ locale, inline = false, defaultType }) {
  const [open, setOpen] = useState(false)
  const [type, setType] = useState(defaultType)
  const [live, setLive] = useState()

  const iframe = useRef()
  useEffect(() => {
    setLive(true)
    function checkHash() {
      if (window.location.hash === "#form" && !inline) setOpen(true)
    }
    checkHash()
    window.addEventListener("hashchange", checkHash, false)
    return () => {
      window.removeEventListener("hashchange", checkHash, false)
    }
  }, [])
  useEffect(() => {
    setType(null)
  }, [open])

  useEffect(() => {
    const handler = event => {
      if (
        ![
          "https://smiley-box.com",
          "https://party.wizito.com",
          "https://party-london.wizito.com",
        ].includes(event.origin)
      )
        return
      if (event.data?.event === "submit") {
        if (window.gtag) {
          window.gtag("event", "contact")
          window.gtag("event", "conversion", {
            send_to: "AW-951050257/CsGfCK_htPUCEJHAv8UD",
          })
        }
        setTimeout(() => {
          window.location = event.data?.target
        }, 500)
      }
    }
    window.addEventListener("message", handler)
    return () => window.removeEventListener("message", handler)
  }, [])

  const close = () => {
    window.history.pushState(
      "",
      document.title,
      window.location.pathname + window.location.search
    )
    setOpen(false)
  }

  if (inline && live)
    return (
      <div key={`form-${locale}`} id="inline-form" className="w-full">
        {defaultType === "individual" && (
          <iframe
            ref={iframe}
            src={
              locale === "fr"
                ? `https://party.wizito.com/form?origin=${
                    origin() || "wizito-party"
                  }&originUrl=${path()}`
                : `https://party-london.wizito.com/form?origin=${
                    origin() || "wizito-party"
                  }&originUrl=${path()}`
            }
            className="w-full"
            style={{ height: "500px" }}
          />
        )}
        {defaultType === "professional" && <ProForm locale={locale} inline />}
      </div>
    )

  if (!open) return null

  if (!type)
    return (
      <Popup
        close={close}
        title={locale === "fr" ? `Qui êtes-vous ? 🤔` : "Who are you? 🤔"}
      >
        <div className="hidden lg:block mb-4 text-gray-600 leading-tight text-lg">
          {locale === "fr"
            ? "Vous souhaitez louer une borne photo, avoir des informations, obtenir un devis ou prendre un café ?"
            : "Do you want to rent a photo booth, get information, a quote or share a coffee?"}
        </div>
        <div>
          <div className="flex flex-col lg:flex-row w-full justify-center items-center">
            <Button onClick={() => setType("professional")} className="m-2">
              {locale === "fr"
                ? `Je suis une entreprise 🏢`
                : "I am a professional? 🏢"}
            </Button>
            <Button
              onClick={() => setType("individual")}
              className="m-2 flex flex-col"
            >
              {locale === "fr"
                ? `Je suis un particulier 🏡`
                : "I am an individual? 🏡"}
            </Button>
          </div>
          {locale === "fr" && (
            <div className="flex w-full justify-center">
              <div className="lg:w-full"></div>
              <div className="w-full text-xs -mt-1 opacity-75">
                événement uniquement le week-end
              </div>
            </div>
          )}
        </div>
      </Popup>
    )

  if (type === "professional")
    return (
      <Popup
        close={close}
        title={
          locale === "fr"
            ? `Nous vous répondons dans l'heure`
            : `We answer you within the hour`
        }
      >
        <ProForm locale={locale} />
      </Popup>
    )

  return (
    <Popup
      close={close}
      title={
        locale === "fr"
          ? `Vérifier les disponibilités 🗓️`
          : `Check availability 🗓️`
      }
    >
      <iframe
        ref={iframe}
        src={
          locale === "fr"
            ? `https://party.wizito.com/form?origin=${
                origin() || "wizito-party"
              }&originUrl=${path()}`
            : `https://party-london.wizito.com/form?origin=${
                origin() || "wizito"
              }&originUrl=${path()}`
        }
        className="w-full"
        style={{ height: "500px" }}
      />
    </Popup>
  )
}
